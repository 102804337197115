import React from 'react'
import ReactGA from 'react-ga'
import CookieConsent from 'react-cookie-consent'
import { StaticQuery, graphql } from 'gatsby'
import { SEO, NavBar, Footer } from '../'
export default ({ children, title, location, description, keywords }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            trackingId
          }
        }
      }
    `}
    render={data => (
      <div>
        <SEO
          title={title}
          description={description}
          keywords={keywords}
        />
        <NavBar />
        {children}
        <Footer />
        <CookieConsent
          cookieSecurity
          enableDeclineButton
          declineButtonText={'Decline'}
          location='bottom'
          buttonText='Accept'
          cookieName='gatsby-gdpr-google-analytics'
          style={{ background: 'rgba(33, 33, 33, 1)' }}
          buttonStyle={{ background: '#ffffff' }}
          expires={30}
          onAccept={() => {
            ReactGA.initialize(data.site.siteMetadata.trackingId)
            ReactGA.set({
              page: location.pathname,
              anonymizeIp: true
            })
            ReactGA.pageview(location.pathname)
          }}
        >
          <p className='text-md-center m-0'>
            🍪 We use third party analytical cookies to personalise and improve our services. By using our site or selecting &apos;Accept&apos;, you consent to our use of cookies in accordance with our <a href='/Cookie-Policy' target='_blank'>Cookie Policy</a>, which also details how to manage them.
          </p>
        </CookieConsent>
      </div>
    )}
  />
)
