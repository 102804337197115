
import React, { Fragment, PureComponent } from 'react'
class SocialLinks extends PureComponent {
  award () {
    return {
      __html: `
      <div id='TA_certificateOfExcellence229' class='TA_certificateOfExcellence'>
        <ul id='SVA6MBa' class='TA_links Lk1q6cm list-unstyled'>
          <li id='4N0qtMm' class='WaqFnC6M3P'>
            <a target='_blank' rel='noreferrer' href='https://www.tripadvisor.co.uk/Restaurant_Review-g3526321-d3513396-Reviews-The_Woolpack-Iwade_Kent_England.html'>
              <img src='https://www.tripadvisor.co.uk/img/cdsi/img2/awards/v2/tchotel_2021_LL-14348-2.png' alt='TripAdvisor' class='widCOEImg' id='CDSWIDCOELOGO' />
            </a>
          </li>
        </ul>
      </div>
      <script async src='https://www.jscache.com/wejs?wtype=certificateOfExcellence&amp;uniq=229&amp;locationId=3513396&amp;lang=en_UK&amp;year=2021&amp;display_version=2' data-loadtrk onLoad='this.loadtrk=true'></script>
    </div>
    `
    }
  }

  render () {
    return (
      <Fragment>
        <div className='col-md-4 col-xs-12'>
          <h2>Stay in touch</h2>
          <ul className='list-unstyled social-links'>
            <li>
              <a className='fab fa-tripadvisor' rel='noopener noreferrer' target='_blank' href='https://www.tripadvisor.co.uk/Restaurant_Review-g3526321-d3513396-Reviews-The_Woolpack_Inn-Iwade_Kent_England.html'/>
            </li>
            <li>
              <a className='fab fa-facebook-f' rel='noopener noreferrer' target='_blank' href='https://www.facebook.com/thewoolpackiwade/'/>
            </li>
          </ul>
          <div dangerouslySetInnerHTML={this.award()} />
        </div>
      </Fragment >
    )
  }
}

export default SocialLinks
