import React, { Fragment, PureComponent } from 'react'
import { OpeningHours, ContactUs, SocialLinks } from '../'
class Footer extends PureComponent {
  render () {
    return (
      <Fragment>
        <div>
          <footer>
            <div className='footer-bottom'>
              <div className='container'>
                <div className='row'>
                  <OpeningHours />
                  <ContactUs />
                  <SocialLinks />
                </div>
                <div className='mt-3'>
                  <p className='pull-right mb-0'>
                    {' '}
                    Copyright © {new Date().getFullYear()} - The Woolpack
                  </p>
                  <a href='/Cookie-Policy'>Cookie Policy</a>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </Fragment>
    )
  }
}
export default Footer
