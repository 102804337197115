import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Gallery from './gallery'

const query = graphql`
  query indexQuery {
    allFile(filter: {relativePath: {regex: "/gallery*/"}}, sort: {order: DESC, fields: modifiedTime}) {
      edges {
        node {
          childImageSharp {
            original {
              width
              height
            }
            fluid {
              ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
            }
          }
        }
      }
    }
  }
`
const LightBox = () => {
  const data = useStaticQuery(query)
  return (
    <Gallery
      columns={width => {
        if (width < 700) {
          return 2
        } else if (width < 1000) {
          return 3
        } else {
          return 6
        }
      }}
      photos={data.allFile.edges}
    />
  )
}

export default LightBox
