import React, { Fragment, PureComponent } from 'react'
import { Navbar, NavItem, Nav } from 'react-bootstrap'

class NavBar extends PureComponent {
  render () {
    return (
      <Fragment>
        <Navbar fixedTop={true}>
          <Navbar.Header>
            <Navbar.Brand>
            </Navbar.Brand>
            <Navbar.Toggle/>
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              <NavItem eventKey={1} href='/'>
                  Home
              </NavItem>
              <NavItem eventKey={2} href='/Find-Us'>
                  Find us
              </NavItem>
              <NavItem eventKey={3} href='/Menus'>
                Menus
              </NavItem>
              <NavItem eventKey={4} href='/Gallery'>
                  Gallery
              </NavItem>
              {/* <NavItem eventKey={6} href='/Events'>
                  Events
                </NavItem> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Fragment>
    )
  }
}

export default NavBar
