import React, { Fragment, PureComponent } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

function renderImage (file) {
  return <Img
    fluid={file.node.childImageSharp.fluid}
  />
}
class Image extends PureComponent {
  render () {
    return (
      <Fragment>
        <div >
          <StaticQuery
            query={graphql`
              query {
                images: allFile(filter:{ extension: { regex: "/jpeg|jpg|png/"}, relativePath: {regex: "/inPage/"}}) {
                edges {
                  node {
                    extension
                    relativePath
                    childImageSharp {
                      fluid(maxWidth: 500, maxHeight:250) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
            `}
            render={({ images }) => renderImage(images.edges.find(image => image.node.relativePath.includes(this.props.image)))}
          />
          <h3 className='hero-text'>{this.props.text}</h3>
        </div>
      </Fragment>
    )
  }
}

export default Image
