import React, { Fragment, PureComponent } from 'react'
class Marks extends PureComponent {
  render () {
    return (
      <Fragment>
        <div className='row'>
          <div className='col-md-offset-3 col-xs-offset-1'>
            <p>Menu’s &amp; pricing are subject to change &amp; availability.</p>
            <h4>Dishes showing <span className='vegetarian-mark'>V</span> are suitable for Vegetarians.</h4>
            <h4>Dishes showing <span className='gf-mark'>GF</span> are suitable for Coeliac&apos;s.</h4>
            <h4>Dishes showing <span className='vg-mark'>VG</span> are suitable for Vegan&apos;s.</h4>
          </div>
        </div>
      </Fragment>
    )
  }
}
export default Marks
