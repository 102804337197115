/* global document */
import { Component } from 'react'
// import { withRouter } from 'react-router-dom'
// import Proptypes from 'proptypes'
class ScrollToTop extends Component {
  componentDidUpdate (prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      document.body.scrollTop = 0
    }
  }

  render () {
    return this.props.children
  }
}

ScrollToTop.propTypes = {
  // location: Proptypes.any.isRequired,
  // children: Proptypes.any.isRequired
}

export default ScrollToTop
