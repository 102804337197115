import React, { Fragment, PureComponent } from 'react'
class Sides extends PureComponent {
  render () {
    return (
      <Fragment>
        <div className='row'>
          <div className='col-md-12 col-xs-12 mb-4'>
            <h3 className='para-h2'>Side Orders</h3>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div>Bowl Of Chips <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span> <div className='pull-right'>£3.50</div></div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div>Bowl Of Cheesy Chips <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span> <div className='pull-right'>£4.50</div></div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div>Side Salad <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span> <div className='pull-right'>£2.95</div></div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div>Ciabatta Garlic Bread<span className='vegetarian-mark'>V</span> <div className='pull-right'>£2.95</div></div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div>Cheesy Ciabatta Garlic Bread<span className='vegetarian-mark'>V</span> <div className='pull-right'>£3.95</div></div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div>Beer Battered Onion Rings <span className='vegetarian-mark'>V</span> <div className='pull-right'>£3.50</div></div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div>Homemade Coleslaw <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span> <div className='pull-right'>£1.95</div></div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Sides
