
import React, { Fragment, PureComponent } from 'react'

class ContactUs extends PureComponent {
  render () {
    return (
      <Fragment>
        <div className='col-md-4 col-xs-12'>
          <h2>Contact Us</h2>
          <h3 property='name'>The Woolpack</h3>
          <p property='address' typeof='PostalAddress'>
            <span property='streetAddress'>17 The Street</span>,<br />
            <span property='postOfficeBoxNumber'>Iwade</span>,<br />
            <span property='addressLocality'>Sittingbourne</span>,
            <span property='addressRegion'>Kent</span><br />
            <span property='postalCode'>ME9 8SH</span>
          </p>
          <p>
            <a className='telephone-number' href='tel:01795472656'>
              <span property='telephone'>01795 472656</span>
            </a>
          </p>
          <p><a href='mailto:woolpack-iwade@virginmedia.com'>Email us at woolpack-iwade@virginmedia.com</a></p>
        </div>
      </Fragment >
    )
  }
}

export default ContactUs
