/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO ({ description, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  // think about this? maybe have one per page?
  // console.log('metaImage', metaImage)
  const jsonLd = (title === 'Home')
    ? <script type='application/ld+json'>
    {`
  {
    "@context": "https://schema.org",
    "@type": "Restaurant",
    "image": [
      "${site.siteMetadata.siteUrl}/images/outsideDark.jpg",
      "${site.siteMetadata.siteUrl}/images/outside.jpg"
    ],
    "@id": "${site.siteMetadata.siteUrl}",
    "name": "The Woolpack",
    "email":"woolpack-iwade@virginmedia.com",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "17 The Street",
      "addressLocality": "Iwade",
      "addressRegion": "Sittingbourne",
      "postalCode": "ME9 8SH",
      "addressCountry": "GB"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 51.3784064,
      "longitude": 0.72983
    },
    "url": "${site.siteMetadata.siteUrl}",
    "telephone": "01795472656",
    "paymentAccepted": "Credit / Debit Card",
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday"
        ],
        "opens": "12:00",
        "closes": "21:30"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ],
        "opens": "12:00",
        "closes": "23:30"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "Sunday",
        "opens": "12:00",
        "closes": "21:30"
      }
    ],
    "menu": "${site.siteMetadata.siteUrl}/Menus",
    "acceptsReservations": "True"
  }
`}
  </script>
    : null
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:description',
          content: metaDescription
        }
      ]
        .concat(
          keywords.length > 0
            ? {
                name: 'keywords',
                content: keywords.join(', ')
              }
            : []
        )
        .concat(meta)
        .concat([
          {
            property: 'og:image',
            content: `${site.siteMetadata.siteUrl}/images/outsideDark.jpg`
          },
          {
            property: 'og:image:width',
            content: 400
          },
          {
            property: 'og:image:height',
            content: 900
          },
          {
            name: 'twitter:card',
            content: 'summary_large_image'
          }
        ])}
    >
      {jsonLd}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
  description: ''
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
}

export default SEO
