import React, { Fragment, PureComponent } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

function renderImage (file) {
  return <Img
    fluid={file.node.childImageSharp.fluid}
    className={'img-rounded'}
  />
}
class Image extends PureComponent {
  render () {
    return (
      <Fragment>
        <StaticQuery
          query={graphql`
            query {
              images: allFile(filter:{ extension: { regex: "/jpeg|jpg|png/"}, relativePath: {regex: "/inPage/"}}) {
              edges {
                node {
                  extension
                  relativePath
                  childImageSharp {
                    fluid(maxWidth: 600, maxHeight: 300) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          `}
          render={({ images }) => renderImage(images.edges.find(image => image.node.relativePath.includes(this.props.image)))}
        />
      </Fragment>
    )
  }
}

export default Image
