import React, { Fragment, PureComponent } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

function renderImage (file, alt = '') {
  return <Img fluid={file.node.childImageSharp.fluid} alt={alt} />
}
class Jumbotron extends PureComponent {
  render () {
    return (
      <Fragment>
        <div className='header-container'>
          <StaticQuery
            query={graphql`
              query {
                images: allFile(
                  filter: {
                    extension: { regex: "/jpeg|jpg|png/" }
                    relativePath: { regex: "/jumbos/" }
                  }
                ) {
                  edges {
                    node {
                      relativePath
                      childImageSharp {
                        fluid(
                          maxWidth: 1800
                          maxHeight: 800
                          srcSetBreakpoints: []
                        ) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={({ images }) =>
              renderImage(
                images.edges.find((image) =>
                  image.node.relativePath.includes(this.props.image)
                ),
                this.props.imageAlt
              )
            }
          />
          {this.props.heading && (
            <div className='hero-text' style={{ fontFamily: 'PT Serif' }}>
              <h1>{this.props.heading}</h1>
              {this.props.subText && (
                <h2 className='hidden-xs'>{this.props.subText}</h2>
              )}
            </div>
          )}
        </div>
      </Fragment>
    )
  }
}

Jumbotron.propTypes = {
  // heading: Proptypes.string,
  // subText: Proptypes.string,
  // imageTag: Proptypes.string
}

export default Jumbotron
