import React, { Fragment, PureComponent } from 'react'
import { Link } from 'gatsby'
class OpeningHours extends PureComponent {
  render () {
    return (
      <Fragment>
        <div className='col-md-4 col-xs-12'>
          <h2 className='para-h2'>Opening times</h2>
          <ul className='opening-times list-unstyled'>
            <li>
              <div className='row'>
                <div className='col-xs-6'>
                  Monday - Thursday
                </div>
                <div className='col-xs-6'>
                  <p>12:00 - 22:00</p>
                </div>
                <div className='col-xs-6'>
                  Friday &amp; Saturday
                </div>
                <div className='col-xs-6'>
                  <p>12:00 - 23:00</p>
                </div>
                <div className='col-xs-6'>
                  Sunday
                </div>
                <div className='col-xs-6'>
                  <p>12:00 - 20:00</p>
                </div>
              </div>
            </li>
            <li>
              <div className='row'>
                <div className='col-xs-12 text-centre'>
                  <Link to='/Menus#opening-times'>Food service times</Link>
                </div>
              </div>
            </li>
          </ul>
          <ul className='list-unstyled list-inline pub-type'>
            <li><div className='fas fa-wifi'/></li>
            <li><div className='fas fa-utensils'/></li>
            <li><div className='fas fa-cocktail'/></li>
            <li><div className='fas fa-music'/></li>
          </ul>
        </div>
      </Fragment >
    )
  }
}

export default OpeningHours
